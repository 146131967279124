import React from 'react'
import { Link } from 'gatsby'

import Layout from 'components/Layout'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import { H1 } from 'styles/typography'

export default () => {
  return (
    <Layout>
      <Section className='u-text-center'>
        <Wrapper>
          <H1>404 Page Not Found</H1>
          <p>The page you requested could not be displayed. <Link to="/">Return to home</Link></p>
        </Wrapper>
      </Section>
    </Layout>
  )
}